<template>
  <section id="landing">
    <page-title>
      <template v-slot:left>
        <ol class="breadcrumb">
          <li class="tooltip-container">
            ECKN: ${{ ecknUSDPrice }} <span :style="{color: ecknUSDPriceChangeColor}">({{ ecknUSDPriceChange }}%)</span><span class="tooltiptext">Changes in last 24 hours</span>
          </li>
        </ol>
      </template>
      <ol class="breadcrumb">
        <li>
          <strong>Smart Contract:</strong>
          <a :href="smartContractLink">{{ smartContractAddress }}</a>
        </li>
      </ol>
    </page-title>
    <div class="wrapper main-wrapper row" style="margin-top: 0">
      <div class="page-content">
        <div class="clearfix"></div>
        <div class="col-lg-12">
          <div class="row">
            <PriceHistoryIframe />
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="col-lg-12">
          <div class="row">
            <token-distribution />
          </div>
        </div>

        <div class="clearfix"></div>

        <div class="col-lg-12">
          <network-transactions />
        </div>

        <div class="clearfix"></div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import NetworkTransactions from "@/components/NetworkTransactions.vue";
import TokenDistribution from "@/components/TokenDistribution.vue";
import PageTitle from "@/components/PageTitle.vue";
//import PriceHistoryChart from "@/components/PriceHistoryChart.vue"
import PriceHistoryIframe from "@/components/PriceHistoryIframe.vue";
export default {
  name: "LandingView",
  components: {
    NetworkTransactions,
    TokenDistribution,
    PageTitle,
    PriceHistoryIframe,
    //PriceHistoryChart
  },
  data() {
    return {};
  },
  computed: {
    smartContractAddress() {
      return process.env.VUE_APP_ECKN_TOKEN_ADDRESS;
    },
    smartContractLink() {
      return `${process.env.VUE_APP_NETWORK_EXPLORER_URL}/token/${process.env.VUE_APP_ECKN_TOKEN_ADDRESS}`;
    },
    ecknUSDPrice() {
      return (this.$store.state.ethUSDPrice / 33).toLocaleString();
    },
    ecknUSDPriceChange() {
      return (this.$store.state.ethUSDPriceChange / 33).toFixed(2);
    },
    ecknUSDPriceChangeColor() {
      return (this.$store.state.ethUSDPriceChange >= 0 && "green") || "red";
    },
  },
  methods: {
    async getEcknPrice() {
      const ecknPrice = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd&include_24hr_change=true"
      );
      this.$store.commit("setEthPrice", ecknPrice.data.ethereum);
    },
  },
  async mounted() {
    this.getEcknPrice();
  },
};
</script>

<style>
.ask-box.wallet {
  background: white !important;
}
.wallet .ask-info h3 {
  color: black !important;
}

    /* Tooltip container */
.tooltip-container {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip-container .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip-container .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip-container:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
</style>
