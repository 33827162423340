<template>
  <div class="row">
    <distribution-chart :holders="holders"/>
    <holders-table :holders="holders"/>
  </div>
</template>

<script>
import covalenthq from "@/services/covalenthq.js";
import HoldersTable from "@/components/HoldersTable.vue";
import DistributionChart from "@/components/DistributionChart.vue"

export default {
  name: "TokenDistribution",
  components: {
    HoldersTable,
    DistributionChart
  },
  data() {
    return {
      holders: [],
      datacollection: null,
    };
  },
  methods: {
    generateRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    fillData() {
      this.datacollection = {
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: [
              this.generateRandomColor(),
              this.generateRandomColor(),
              this.generateRandomColor(),
            ],
            hoverOffset: 4,
          },
        ],
      };
    },
    getTokenHolders() {
      let self = this
      covalenthq
        .get(
          `tokens/${process.env.VUE_APP_ECKN_TOKEN_ADDRESS}/token_holders/`,
          {
            params: {
              format: "JSON",
              limit:1000
            },
          }
        )
        .then((response) => {
          self.holders = response.data.data.items.map((i) => {
            return {
              address: i.address,
              balance: parseFloat(i.balance)/1000000000000000000,
              percentage: parseFloat(i.balance)/2000000000000000000000000000*100
            }
          })
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.fillData();
  },
  mounted() {
    this.getTokenHolders();
  },
};
</script>
<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
