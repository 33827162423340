<template>
  <div class="col-xs-12 col-md-4">
    <section class="box">
      <header class="panel_header">
        <h2 class="title pull-left">Distribución del Token</h2>
      </header>
      <div class="content-body pb10">
        <div class="row">
          <div
            class="
              col-xs-8 col-md-offset-2 col-sm-offset-2 col-xs-offset-2
              mb-20
            "
          >
            <div class="small">
              <pie-chart :chart-data="pieData"></pie-chart>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PieChart from "./PieChart.js";

export default {
  name: "DistributionChart",
  props: {
    holders: {
      type: Array,
      required: true,
    },
  },
  components: {
    PieChart,
  },
  computed: {
    pieData() {
      let self = this;
      let newArray = this.holders.slice();
      const dataArray = newArray.map((el) => {
        return el.percentage;
      });
      /*
          const labelsArray = newArray.map((el) => {
              return el.address
          })
          */
      const colorsArray = newArray.map(() => {
        return self.generateRandomColor();
      });

      return {
        datasets: [
          {
            data: dataArray,
            backgroundColor: colorsArray,
            hoverOffset: 4,
          },
        ],
      };
    },
  },
  methods: {
    generateRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
};
</script>
<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
