<template>
  <div class="col-xs-12 affiliate-social">
    <div class="row">
      <div class="col-lg-10 col-lg-offset-1 mt-15">
        <div class="uprofile-social">
          <a @click="connect('metamask')" class="btn btn-primary btn-lg twitter"
            >CONECTAR METAMASK</a
          >
          <a @click="connect('torus')" class="btn btn-primary btn-lg twitter"
            >CONECTAR TORUS</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customEnable from "@/plugins/torus.js"

export default {
  name: "WalletConnectionButtons",
  computed: {
    web3Connected() {
      return this.$store.state.ethAddress != null;
    },
    connectedAddress() {
      return this.$store.state.ethAddress;
    },
    metamaskInstalled() {
      return window.ethereum && window.ethereum.isMetaMask;
    },
    isMaticNetwork() {
      return window.ethereum.networkVersion == process.env.VUE_APP_CHAIN_ID;
    },
  },
  mounted() {},
  methods: {
    addNetwork() {
      let self = this
      const params = [
        {
          chainId: "0x" + parseInt(process.env.VUE_APP_CHAIN_ID).toString(16),
          chainName: process.env.VUE_APP_NETWORK_NAME_FULL,
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          rpcUrls: [process.env.VUE_APP_METAMASK_RPC_URL],
          blockExplorerUrls: [process.env.VUE_APP_NETWORK_EXPLORER_URL],
        },
      ];

      window.ethereum
        .request({ method: "wallet_addEthereumChain", params })
        .then(() => self.connect())
        .catch((error) => console.log("Error", error.message));
    },
    async connect(provider) {
      
      let user;
      if (provider == "torus") {
        this.$moralis.setEnableWeb3(customEnable )
      }
      window.web3 = await this.$moralis.enableWeb3();
      user  = await this.$moralis.Web3.authenticate({
        provider: provider,
        chainId: process.env.VUE_APP_CHAIN_ID,
      });
      this.$store.commit("setAddress", user.attributes.ethAddress);
      this.$store.commit("setProvider", provider);
      this.$store.commit("setUser", user);
    },
  },
};
</script>
