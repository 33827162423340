<template>
  <section id="dashboard">
    <page-title title="Dashboard"> </page-title>
    <wallet-connection-buttons v-if="!web3Connected" />
    <div v-else class="wrapper" style="">
      <div class="page-content">
        <div class="col-lg-12">
          <crypto-balances
            :eckn="ecknBalance"
            :matic="maticBalance"
            :account="account"
          />
        </div>

        <div class="clearfix"></div>
        <div class="clearfix"></div>
        <my-transactions />

        <div class="clearfix"></div>
      </div>
    </div>
  </section>
</template>

<script>
import CryptoBalances from "@/components/CryptoBalances.vue";
import MyTransactions from "@/components/MyTransactions.vue";
import WalletConnectionButtons from "@/components/WalletConnectionButtons.vue";
import PageTitle from "@/components/PageTitle.vue";


export default {
  name: "LandingView",
  components: {
    MyTransactions,
    CryptoBalances,
    WalletConnectionButtons,
    PageTitle,
  },
  data() {
    return {
      web3: null,
      ecknBalance: 0,
      maticBalance: 0,
      account: "",
    };
  },
  computed: {
    web3Connected() {
      return this.$store.state.ethAddress.length > 0;
    },
  },
  methods: {},
  async mounted() {
    if (!this.web3Connected) {
      return 
    }
    const options = { chain: process.env.VUE_APP_NETWORK_NAME, address: this.$store.state.ethAddress };

    const maticBalance = await this.$moralis.Web3API.account.getNativeBalance(options);

    this.maticBalance = maticBalance.balance/1000000000000000000
    
    const erc20Balance = await this.$moralis.Web3API.account.getTokenBalances(
      options
    );
    const ecobanBalance = erc20Balance.filter((t) => {
      return (
        t.token_address ==
        process.env.VUE_APP_ECKN_TOKEN_ADDRESS.toLocaleLowerCase()
      );
    });
    this.ecknBalance =
      (ecobanBalance.length == 1 &&
        parseFloat(ecobanBalance[0].balance) / 1000000000000000000) ||
      0;
      
  },
};
</script>

<style>
.ask-box.wallet {
  background: white !important;
}
.wallet .ask-info h3 {
  color: black !important;
}
</style>
