<template>
  <section id="club-eckn">
    <page-title title="Club ECKN"> </page-title>
    <my-club-requests/>
  </section>
</template>

<script>

import PageTitle from "@/components/PageTitle.vue";
import MyClubRequests from "@/components/MyClubRequests.vue"

export default {
  name: "ClubEcknView",
  components: {
    PageTitle,
    MyClubRequests
  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {},
  async mounted() {
      
      
  },
};
</script>

<style>

</style>
