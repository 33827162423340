<template>
  <section class="box box-rounded">
    <div class="row">
      <div class="col-xs-12">
        <div class="option-identity-wrapper mb-15">
          <h3 class="boldy mt-0">Dirección Wallet Ethereum</h3>
          <div class="row">
            <div class="col-lg-8">
              <div class="form-group mb-0">
                <label class="form-label mb-10">Dirección</label>
                <span class="desc">(Compártela para recibir pagos)</span>
                <div class="input-group primary row flex marginAuto">
                  <span class="input-group-addon flex-center justify-content-center">
                    <img src="/images/eckn_blanco.png"/>
                  </span>
                  <input id="my-address" readonly type="text" class="form-control height100 w-fit-content" :value="account" />
                  <a @click="copyAddress" href="#" class="btn btn-primary btn-lg btn-corner5 left15" ><i class="fa fa-copy right15"></i>Copiar</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="option-identity-wrapper no-mb">
          <div class="row">
            <div class="col-md-4">
              <img :src="qrDataUrl" />
            </div>
            <div class="col-md-8">
              <div class="scan-info">
                <h3 class="bold">Envía ECKN</h3>
                <div class="row marginAuto">
                    <div class="form-group mb-0">
                      <div class="input-group primary">
                        <span class="input-group-addon">
                          <span class="arrow"></span>
                            <img src="/images/eckn_blanco.png"/>
                        </span>
                        <input type="text" class="form-control" v-model="transferTarget" />
                      </div>
                    </div>
                </div>
                <div class="row marginAuto">
                  <div class="mt-10 marginAuto flex-center">
                    <input type="number" class="form-control" placeholder="cantidad" v-model="transferAmount"/>
                    <a @click="sendTransaction" href="#" class="btn btn-primary btn-lg btn-corner5 left15">
                      <i class="fa fa-long-arrow-up complete color-white "></i>Enviar
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EthereumQRPlugin from "ethereum-qr-code";
import customEnable from "@/plugins/torus.js"

export default {
  name: "WalletBox",
  components: {},
  computed: {
    account() {
      return this.$store.state.ethAddress;
    },
  },
  data() {
    return {
      transferTarget: "0x01B7A56250d12C37b20E45F8425d2eA63b9888F8",
      transferAmount: 0.1,
      qrDataUrl: "",
    };
  },
  watch: {
    transferTarget: function () {
      this.qrString();
    },
    transferAmount: function () {
      this.qrString();
    },
  },
  mounted() {
    this.qrString();
  },
  methods: {
    qrString() {
      let self = this;
      const qr = new EthereumQRPlugin();
      const qrCode = qr.toDataUrl({
        to: this.transferTarget,
        from: this.account,
        gas: 100000,
        mode: "erc20__transfer",
        argsDefaults: [
          {
            name: "to",
            value: this.transferTarget,
          },
          {
            name: "value",
            value: this.transferAmount,
          },
        ],
      });

      qrCode.then((qrCodeDataUri) => {
        self.qrDataUrl = qrCodeDataUri.dataURL;
      });
    },
    copyAddress() {
      var copyText = document.querySelector("#my-address");
      copyText.select();
      document.execCommand("copy");
    },
    async sendTransaction() {
      if (this.$store.state.provider == "torus") {
        this.$moralis.setEnableWeb3(customEnable )
        window.web3 = await this.$moralis.enableWeb3();
      }
      
      const options = {
        type: "erc20",
        amount: this.$moralis.Units.Token(this.transferAmount.toString(), "18"),
        receiver: this.transferTarget,
        contractAddress: process.env.VUE_APP_ECKN_TOKEN_ADDRESS,
      };
      let result = await this.$moralis.transfer(options);
      console.log(result);
    },
  },
};
</script>
