<template>
  <section class="box nobox marginBottom0 visible">
    <div class="content-body">
      <div class="row">
        <div class="col-lg-6 col-sm-6 col-xs-12">
          <div class="row grid grid-autoflow-columns cryptobalances-grid">
              <crypto-balance
                i=""
                image="/images/eckn.png"
                color="pink"
                symbol="ECKN"
                :balance="eckn.toLocaleString()"
                name="Ecoban"
              >
                <button v-if="isMetamask" @click="addTokenToMetamask" id="addButton">+ Añadir a Metamask</button>
              </crypto-balance>
              <crypto-balance
                i=""
                image="https://criptoboletin.com/wp-content/uploads/2021/06/que-es-polygon-matic-criptoboletin.png"
                color="violet"
                symbol="MATIC"
                :balance="matic.toLocaleString()"
                name="MATIC"
              />
              <instant-buy/>
          </div>
        </div>

        <div class="col-lg-6 col-sm-6 col-xs-12">
          <wallet-box :account="account" @send="sendTransaction($event)" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CryptoBalance from "@/components/CryptoBalance.vue";
import InstantBuy from "@/components/InstantBuy.vue";
import WalletBox from "@/components/WalletBox.vue";

export default {
  name: "CryptoBalances",
  components: { CryptoBalance, InstantBuy, WalletBox },
  props: ["eckn", "account", "matic"],
  computed: {
    isMetamask() {
      return this.$store.state.provider == "metamask";
    }
  },
  methods: {
    async addTokenToMetamask() {
      const tokenAddress = process.env.VUE_APP_ECKN_TOKEN_ADDRESS;
      const tokenSymbol = "ECKN";
      const tokenDecimals = 18;
      const tokenImage = process.env.VUE_APP_ECKN_LOGO_URL;

      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage, 
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    sendTransaction(eventData) {
      this.$emit("send", eventData);
    },
  },
};
</script>

<style>
  #addButton {
    background: linear-gradient(white, #e0e0e0);
    border: none;
    border-radius: 50px;
    box-shadow: 0 0px 10px #8b0c56;
    padding:10px 15px;
    margin-bottom: 10px;
    transition: .5s;
    font-weight: bold;
    color: #bd0f75;
  }
  #addButton:hover {
    box-shadow: 0 10px 20px -10px #ff0095;
    color: #ff0095;
  }

.row.cryptobalances-grid:before {
  display: none;
}
  .cryptobalances-grid {
    column-gap: 10px;
  }
  .cryptobalances-grid >div:nth-child(2) {
    grid-row: 1;
    grid-column: 1;
  }
  .cryptobalances-grid >div:nth-child(2) {
    grid-row: 1;
    grid-column: 2;
  }
  .cryptobalances-grid >div:nth-child(3) {
    grid-row: 2;
    grid-column: 1 / 3;
  }
</style>