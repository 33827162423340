import axios from 'axios';

const instance = axios.create({
    baseURL: `https://api.covalenthq.com/v1/${process.env.VUE_APP_CHAIN_ID}/`,
  }); 
  
  instance.interceptors.request.use(config => {
    config.params = {
      key: process.env.VUE_APP_COVALENTHQ_KEY,
      ...config.params,
    };
    return config;
  });
  
  export default instance; 
