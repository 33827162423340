<template>
    <iframe src="https://eckn-chart.vercel.app/" style="width: 100%; height: 450px; min-height: 450px; border: none;"></iframe>

</template>

<script >

export default {
    name: "PriceHistoryIframe"
    
}
</script>