<template>
  <div class="col-xs-12">
    <div class="page-title">
      <div class="pull-left">
        <h1 v-if="title" class="title">{{title}}</h1>
        <slot name="left"></slot>
      </div>

      <div  class="pull-right hidden-xs">
        <slot></slot>
        <ol v-if="breadcrumb.length > 0" class="breadcrumb">
          <li>
            <a href="#"><i class="fa fa-home"></i>Home</a>
          </li>
          <li class="active">
            <strong>Affiliate Program</strong>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "PageTitle",
  props: {
      title: {
          type: String,
          default: ""
      },
      breadcrumb: {
          type: Array,
          default: () => {return []}
      }
  }
};
</script>