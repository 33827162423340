<template>
  <div class="col-lg-12">
    <section class="box box-rounded">
      <header class="panel_header gradient-orange">
        <h2 class="title pull-left">Mis transacciones</h2>
      </header>
      <div class="content-body">
        <div class="row">
          <div class="col-xs-12">
              <vue-bootstrap-table
                :columns="columns"
                :values="values"
                :show-filter="true"
                :sortable="true"
                :paginated="true"
                :selectable="false"
                :multi-column-sortable="true"
                :filter-case-sensitive="false"
                id="table-scroll"
              >
              </vue-bootstrap-table>
            
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueBootstrapTable from "vue2-bootstrap-table2";
import covalenthq from "@/services/covalenthq.js";


export default {
  name: "MyTransactions",
  components: {
    VueBootstrapTable: VueBootstrapTable,
  },
  data() {
    return {
      columns: [
        {
          title: "tx",
          visible: true,
          editable: false,
          filterable: false,
          renderfunction: (colname, entry) => { return `<a href='${process.env.VUE_APP_NETWORK_EXPLORER_URL}/tx/${entry.tx}' target='_blank'>${entry.tx}</a>`}
        },
        {
          title: "to",
          visible: true,
          editable: true,
          renderfunction: (colname, entry) => { return `<a href='${process.env.VUE_APP_NETWORK_EXPLORER_URL}/address/${entry.to}' target='_blank'>${entry.to}</a>`}
        },
        {
          title: "value",
          visible: true,
          editable: true,
        },
        {
          title: "timestamp",
          visible: true,
          editable: false,
          sortable: false,
        },
      ],
      values: [
     
      ],
    };
  },
  methods: {},
  created() {},
  async mounted() {
    let self = this
      covalenthq
        .get(
          `address/${this.$store.state.ethAddress}/transfers_v2/`,
          {
            params: {
              "contract-address": process.env.VUE_APP_ECKN_TOKEN_ADDRESS,
              format: "JSON",
              limit: 50
            },
          }
        )
        .then((response) => {
          self.values = response.data.data.items.map((i) => {
            return {
              to: i.transfers[0].to_address,
              tx: i.transfers[0].tx_hash,
              timestamp: i.transfers[0].block_signed_at,
              value: (parseFloat(i.transfers[0].delta)/1000000000000000000).toLocaleString(),
            }
          })
        })
        .catch((err) => {
          console.log(err);
        });
  },
};
</script>
<style>
.box.box-rounded {
  border-radius: 20px;
}
.panel_header {
  border: none;
}
.panel_header .title {
  color: #ffffff;
  font-weight: bold;
  font-size: 2em;
}
#table-scroll .row .col-sm-12 {
  overflow-x: auto !important;
}
#table-scroll .row {
  margin: 0;
}
#table-scroll .col-sm-12 {
  padding: 0;
}
</style>
