<template>
  <div class="col-lg-12">
    <section class="box box-rounded">
      <header class="panel_header gradient-orange">
        <h2 class="title pull-left">Mis solicitudes</h2>
      </header>
      <div class="content-body">
        <div class="row">
          <div class="col-xs-12">
              <vue-bootstrap-table
                :columns="columns"
                :values="values"
                :show-filter="true"
                :sortable="true"
                :paginated="true"
                :selectable="false"
                :multi-column-sortable="true"
                :filter-case-sensitive="false"
                id="table-scroll"
              >
              </vue-bootstrap-table>
            
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueBootstrapTable from "vue2-bootstrap-table2";


export default {
  name: "MyClubRequests",
  components: {
    VueBootstrapTable: VueBootstrapTable,
  },
  data() {
    return {
      columns: [
        {
          title: "Referencia",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => { return `${entry.request_reference}`}
        },
        {
          title: "URL",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => { return `<a href="${entry.product_url}"> IR </a>`}
        },
        {
          title: "Precio",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => { return `${entry.product_price} ${entry.product_currency}`}
        },
        {
          title: "Precio ECKN",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => { return `${entry.estimated_eckn_price}`}
        },
        {
          title: "Estado",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => { return (entry.approved && "Aprobada")||(entry.rejected && "Rechazada")||"Pendiente" }
        },
      ],
      values: [

      ],
    };
  },
  methods: {},
  created() {},
  async mounted() {
    this.values = await this.$moralis.Cloud.run("GetMyClubRequests");
    console.log(this.values)


  },
};
</script>
<style>
.box.box-rounded {
  border-radius: 20px;
}
.panel_header {
  border: none;
}
.panel_header .title {
  color: #ffffff;
  font-weight: bold;
  font-size: 2em;
}
#table-scroll .row .col-sm-12 {
  overflow-x: auto !important;
}
#table-scroll .row {
  margin: 0;
}
#table-scroll .col-sm-12 {
  padding: 0;
}
</style>
