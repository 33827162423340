<template>
  <div class="col-lg-8">
    <section class="box">
      <header class="panel_header">
        <h2 class="title pull-left">Token Holders</h2>
      </header>
      <div class="content-body">
        <div class="row">
          <div class="col-xs-12">
            <vue-bootstrap-table
                :columns="columns"
                :values="sortedHolders"
                :show-filter="true"
                :sortable="true"
                :paginated="true"
                :selectable="false"
                :multi-column-sortable="true"
                :filter-case-sensitive="false"
              >
              </vue-bootstrap-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueBootstrapTable from "vue2-bootstrap-table2";


export default {
  name: "HoldersTable",
  components: {VueBootstrapTable: VueBootstrapTable},
  props: {
    holders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      columns: [
        {
          title: "address",
          visible: true,
          editable: false,
          renderfunction: (colname, entry) => { return `<a href='${process.env.VUE_APP_NETWORK_EXPLORER_URL}/address/${entry.address}' target='_blank'>${entry.address}</a>`}
        },
        {
          title: "balance",
          visible: true,
          editable: false,
          renderfunction: (colname, entry) => { return entry.balance.toLocaleString()}
        },
        {
          title: "percentage",
          visible: true,
          editable: false,
          sortable: true,
          renderfunction: (colname, entry) => { return entry.percentage.toFixed(8)}
        },
      ],
    };
  },
  computed: {
    sortedHolders() {
      let newArray = this.holders.slice();
      return newArray.sort((a, b) =>
        a.balance > b.balance ? -1 : b.balance > a.balance ? 1 : 0
      );
    },
  },
};
</script>
