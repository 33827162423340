<template>
    <div :class="`statistics-box text-center gradient-${color} mt-15`">
      <div class="crypto-icon mb-15">
        <i :class="`${i} w-text`"></i>
        <img class="" :src="image"/>
      </div>
      <h4 class="coin-name boldy w-text">Saldo {{name}}</h4>
      <slot></slot>
      <p class="mb-0 g-text">{{balance}} {{symbol}}</p>
    </div>
</template>

<script>
export default {
  name: "CryptoBalance",
  props: {
      i: {
          type: String,
          required: true
      },
      color: {
          type: String,
          required: true
      },
      symbol: {
          type: String,
          required: true
      },
      image: {
          type: String,
          required: true
      },
      balance: {
          type: String,
          required: true
      },
      name: {
          type: String,
          required: true
      }
  }
};
</script>

<style scoped>
.crypto-icon {
    aspect-ratio: 1;
    background: white;
    padding: 10px;
    width: 60px;
    height: 60px;
    margin: auto;
    border-radius: 100px;
    display: flex;
    align-content: center;
}
.crypto-icon img {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}
</style>