<template>
  <section class="box">
    <header class="panel_header">
      <h2 class="title pull-left">Transacciones de la red</h2>
    </header>
    <div class="content-body">
      <div class="row">
        <div class="col-xs-12">
          <vue-bootstrap-table
            :columns="columns"
            :show-filter="false"
            :show-column-picker="false"
            :selectable="false"
            :paginated="true"
            :multi-column-sortable="false"
            :ajax="ajaxConf"
          >
          </vue-bootstrap-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueBootstrapTable from "@/vue2-bootstrap/VueBootstrapTable.vue";

export default {
  name: "NetworkTransactions",
  components: { VueBootstrapTable: VueBootstrapTable },
  data() {
    return {
      logging: [],
      ajaxConf: {
        enabled: true,
        url: `${process.env.VUE_APP_MORALIS_SERVER_URL}/functions/ECKNTransactions?_ApplicationId=${process.env.VUE_APP_MORALIS_APP_ID}`,
        method: "GET",
        delegate: true,
      },
      columns: [
        {
          title: "tx",
          visible: true,
          editable: false,
          filterable: false,
          sortable: false,
          renderfunction: (colname, entry) => {
            return `<a href='${process.env.VUE_APP_NETWORK_EXPLORER_URL}/tx/${entry.transaction_hash}' target='_blank'>${entry.transaction_hash}</a>`;
          },
        },
        {
          title: "to",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => {
            return `<a href='${process.env.VUE_APP_NETWORK_EXPLORER_URL}/address/${entry.to}' target='_blank'>${entry.to}</a>`;
          },
        },
        {
          title: "from",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => {
            return `<a href='${process.env.VUE_APP_NETWORK_EXPLORER_URL}/address/${entry.from}' target='_blank'>${entry.from}</a>`;
          },
        },
        {
          title: "value",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => {
            return (parseFloat(entry.value) / 1000000000000000000).toLocaleString();
          },
        },
        {
          title: "timestamp",
          visible: true,
          editable: false,
          sortable: false,
          renderfunction: (colname, entry) => {
            return entry.createdAt;
          },
        },
      ]
    };
  },
  computed: {
    explorerURL() {
      return process.env.VUE_APP_NETWORK_EXPLORER_URL;
    }
  },
  methods: {},
  created: function () {},
};
</script>
