import Torus from "@toruslabs/torus-embed";
import Web3 from "web3";

class MoralisTorusProvider {
    torus = new Torus({})
    
    async activate() {
        this.provider = await this.torus.init(
            {
                enableLogging: true,
                showTorusButton: false,
                network: {
                    host: process.env.VUE_APP_METAMASK_RPC_URL,
                    networkName: process.env.VUE_APP_NETWORK_NAME_FULL,
                    chainId: process.env.VUE_APP_CHAIN_ID,
                    blockExplorer: process.env.VUE_APP_NETWORK_EXPLORER_URL,
                    ticker: 'MATIC',
                    tickerName: 'MATIC',
                },
            })
        await this.torus.login();
        
        const MWeb3 = typeof Web3 === 'function' ? Web3 : window.Web3;
        this.web3 = new MWeb3(this.torus.provider);
        this.isActivated = true;
        return this.web3;
    }
}

const customEnable = async () => {
    const web3Provider = new MoralisTorusProvider();
    const web3 = await web3Provider.activate();
    return web3;
  }

export default customEnable